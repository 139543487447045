.hero {
  background-image: url("../images/bg.png");
  background-position: center;
  background-size: cover;
}
.footer {
  background-image: url("../images/footer.png");
  background-position: center;
  background-size: cover;
}
